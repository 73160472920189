h2 {
  font-size: 3.5em;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  font-family: Lora;
}



.App {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.content-page-container {
  flex-direction: column;
}
.content-page {
  width: 75vw;
  height: 100vh;
  margin: 0 auto;
}

.content-page-white {
  background-color: white;
  color: black;
}

.content-page-black {
  background-color: black;
  color: bisque;
}